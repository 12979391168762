import config from './config';

// Auth config
export const authConfig = {
  region: config.cognito.REGION,
  userPoolId: config.cognito.USER_POOL_ID,
  identityPoolId: config.cognito.IDENTITY_POOL_ID,
  userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  cookieStorage: {
    domain: config.cookie.domain,
    secure: config.app.env !== 'development',
  },
  authenticationFlowType: 'USER_SRP_AUTH',
};
if (config.oauth.domain) {
  authConfig.oauth = {
    domain: config.oauth.domain,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: config.endpoints.loginURL,
    redirectSignOut: config.endpoints.loginURL,
    // 'code' for Authorization code grant
    // 'token' for Implicit grant
    responseType: 'token',
    // optional, for Cognito hosted ui specified options
    options: {
      // Indicates if the data collection is enabled to support Cognito
      // advanced security features. By default, this flag is set to true.
      AdvancedSecurityDataCollectionFlag: false,
    },
  };
}

const endpoints = [];

if (config.gateways.lawyer.endpoint) {
  endpoints.push({
    name: 'lawyerAPI',
    endpoint: config.gateways.lawyer.endpoint,
    region: config.gateways.lawyer.region,
    custom_header: async () => ({
      'Content-Type': 'application/json',
      'X-Api-Token': config.gateways.lawyer.token,
    }),
  });
}


// API config
export const apiConfig = {
  endpoints,
};
