import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from '../../Routes';
import GA from '../../googleAnalytics';
import config from '../../config';
import { dayClass, transformOrigin, randomClass } from '../../utils/bgStyle';
import './styles.css';

const App = () => (
  <div className="App">
    <div className={`background ${randomClass(config.app.bgClass, config.app.bgCount || 1) || dayClass()}`} style={{ transformOrigin }} />
    <BrowserRouter>
      {GA.init() && <GA.RouteTracker />}
      <Routes />
    </BrowserRouter>
  </div>
);

export default App;
